﻿*, ::after, ::before {
    box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    min-height: 100%;
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: minmax(200px, 1fr);
    background: url("../img/bg.png") center center repeat;
    margin: 0;
    line-height: 1.5;
    color: #212529;
}

.footer {
    grid-row-start: 2;
    grid-row-end: 3;
    background: url("../img/bg-menu.png") repeat;
    box-shadow: 0 0 13px 3px rgba(0,0,0,0.27);
}

    .footer a {
        text-decoration: none;
        color: #212529;
    }

.top-footer {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    margin: 20px;
}

body {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 16px;
}

header {
    padding: 7px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 0 25px 1px rgba(0,0,0,0.1);
}

.copyright {
    text-align: center;
}

h1 {
    text-transform: uppercase;
    font-size: 52px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 35px;
    margin-top: 25px;
}

h2 {
    text-transform: uppercase;
    font-size: 46px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 35px;
    margin-top: 25px;
}

.phone {
    color: #231916;
    font-weight: 700;
    font-size: 30px;
}

.phone a {
    color: #231916;
    text-decoration: none;
}

.oferta {
    text-align: center;
    font-size: 12px;
    margin: 0 20px 10px;
}

/*
Masonry
 */
.grid-item img {
    border: 2px solid #f1f1f1;
    overflow: hidden;
}

@media screen and (min-width: 1500px) {
    .grid-sizer {
        width: 13%;
    }

    .grid-item {
        width: 13%;
        margin-bottom: 1%;
    }

    .gutter-sizer {
        width: 1%;
    }
}

@media screen and (min-width: 1150px) and (max-width: 1499px) {
    .grid-sizer {
        width: 23%;
    }

    .grid-item {
        width: 23%;
        margin-bottom: 2%;
    }

    .gutter-sizer {
        width: 2%;
    }
}

@media screen and (min-width: 685px) and (max-width: 1149px) {
    .grid-sizer {
        width: 32%;
    }

    .grid-item {
        width: 32%;
        margin-bottom: 2%;
    }

    .gutter-sizer {
        width: 2%;
    }
}

@media screen and (min-width: 595px) and (max-width: 684px) {
    .grid-sizer {
        width: 48%;
    }

    .grid-item {
        width: 48%;
        margin-bottom: 3%;
    }

    .gutter-sizer {
        width: 2%;
    }
}

@media screen and (min-width: 450px) and (max-width: 594px) {
    .grid-sizer {
        width: 48%;
    }

    .grid-item {
        width: 48%;
        margin-bottom: 2%;
    }

    .gutter-sizer {
        width: 2%;
    }
}

@media screen and (max-width: 499px) {
    .grid-sizer {
        width: 100%;
    }

    .grid-item {
        width: 100%;
        margin-bottom: 3%;
    }

    .gutter-sizer {
        width: 0;
    }
}

.grid img {
    max-width: 100%;
    height: auto;
}

.navbar {
    padding: 0 1rem;
    margin-bottom: 0;
}

@media (max-width: 991px) {
    .navbar {
        padding: .3rem 1rem;
    }
}

@media (max-width: 991.98px) {
    .offcanvas-collapse {
        top: 40px;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .4rem;
        padding-left: .4rem;
    }
}

.navbar-light .navbar-nav .nav-link {
    color: #333333;
    font-size: 16px;
    font-weight: bold;
}

.navbar-light .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show > .nav-link {
    color: #333333;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
    color: #333333;
}

.bg-light {
    background: url(../img/bg-menu.png) repeat !important;
    /*border-bottom: 3px solid #458d8f;*/
    box-shadow: 0 0 13px 3px rgba(0,0,0,0.27);
}

@media (max-width: 991.98px) {
    .offcanvas-collapse {
        background: url(../img/bg-menu.png) repeat !important;
    }
}

.container-my {
    max-width: 1200px;
    margin: 0 auto;
}

.center-text-block {
    max-width: 980px;
    margin: 0 auto;
}

.center-main-block {
    max-width: 1135px;
    margin: 0 auto;
}

    .center-text-block img {
        margin: 0 auto;
        display: block;
        max-width: 100%;
        height: auto;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .center-main-block img {
        margin: 0 auto;
        display: block;
        max-width: 100%;
        height: auto;
    }

.news-item {
    background: #fff;
    box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.2), inset 0 1px 10px 0 rgba(0, 0, 0, 0.19);
    text-align: center;
    padding: 20px;
    margin-bottom: 20px;
}

.news-link a {
    font-size: 30px;
    text-transform: uppercase;
    text-decoration: none;
    color: #212529;
}

@media (max-width: 500px) {
    .news-link a {
        font-size: 24px;
    }
}

.parent-catalog {
    font-size: 32px;
    color: #F5A101;
    text-transform: uppercase;
    font-weight: 700;
    display: block;
}
.parent-catalog:hover {
    color: #F5A101;
    text-decoration: none
}
.children-catalog {
    font-size: 22px;
    color: #333;
    display: block;
}
    .children-catalog:hover {
        color: #333;
        text-decoration: none
    }

.pdf {
    background-image: url(../img/pdf.png);
    background-repeat: no-repeat;
    padding: 0 0 0 35px;
    display: block;
    font-size: 22px;
    color: #333;
    text-decoration: none
}

.pdf:hover {
    text-decoration: none
}

.sucсess-message {
    text-align: center;
    font-size: 22px;
    margin-bottom: 20px;
    color: green;
}
.error-message {
    text-align: center;
    font-size: 22px;
    margin-bottom: 20px;
    color: red;
}

.partner-name {
    font-size: 26px;
    color: #000000;
    text-transform: uppercase
}

.partner-link {
    display: inline-block;
    font-size: 22px;
    color: #000000;
    margin-bottom: 10px;
}

.link-td a {
    font-size: 26px;
    color: darkblue;
}

.flex-products {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    margin-top: 30px;
}

.product-title {
    margin-top: 20px;
}

.product-title a {
    font-size: 26px;
    color: #c50027;
    text-transform: uppercase;
}

.product-box {
    background: #fff;
    box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.2), inset 0 1px 10px 0 rgba(0, 0, 0, 0.19);
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
}

@media (max-width: 523px) {
    h1 {
        font-size: 28px;
    }
    h2 {
        font-size: 26px;
    }
}

.flex-news-date {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.last-news {
    background-color: #e80000;
    color: white;
    padding: 2px 10px;
}

#allInfoCount, #newsCount, #informationCount {
    color: #e40000;
}

/* Modal Window */

.close-modal {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.modal-sale {
    margin: auto;
    position: fixed;
    bottom: 10px;
    right: 10px;
    padding: 2rem 171px 2rem 2rem;
    border-radius: 5px;
    box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
    z-index: 1040;
    background: url(../img/managerGirl.png) right bottom no-repeat, url(../img/bg.png) center center repeat;
    min-height: 288px;
    min-width: 288px;
}

.hidden {
    display: none;
}

.call-to-action-back-call {
    font-size: 18px;
    margin-top: 20px;
}

.phone-back-call {
    display: block;
    margin: 20px 0;
    border: 1px solid #105822;
    border-radius: 5px;
    width: 100%;
    padding: 10px;
}

.button-back-call {
    font-size: 18px;
    color: white;
    background: #749c2e;
    border: 1px solid #105822;
    border-radius: 5px;
    padding: 5px 20px;
    cursor: pointer;
}

.button-back-call:hover {
    background: #688c2a;
}

/* Telegram */

.telegram {
    position: fixed;
    bottom: 50px;
    right: 50px;
}

.products {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-gap: 15px;
    justify-items: center;
    margin-bottom: 30px;
}

@media (max-width: 500px) {
    .products {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 15px;
        justify-items: center;
        margin-bottom: 30px;
    }
}

.product-item {
    position: relative;
}

.product-item-title {
    position: absolute;
    top: 20px;
    left: 30px;
    right: 30px;
    bottom: 30px;
    color: white;
    font-weight: 700;
    font-size: 30px;
    text-shadow: 0 0 15px #231916;
    letter-spacing: 0.05em;
}

.product-item-more {
    position: absolute;
    right: 30px;
    bottom: 30px;
    color: white;
    font-weight: 700;
    font-size: 16px;
    padding: 5px 15px;
    border: 2px solid white;
    border-radius: 30px;
    text-shadow: 0 0 15px #231916;
    letter-spacing: 0.05em;
}

.product-item-link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
}

.main-bg {
    background: url("../img/manager.png") no-repeat left 40px bottom, url("../img/main-img.jpg") no-repeat center center;
    height: 758px;
    margin-bottom: 30px;
    padding: 20px;
    position: relative;
    overflow: hidden;
}

.advantages {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    text-shadow: 0 0 15px white;
}

.advantages div {
    margin-bottom: 20px;
}

.main-padding {
    padding: 0 30px 30px;
}

.highlight {
    position:absolute;
    top: 0;
    bottom: 0;
    right: -758px;
    width: 758px;
    height: 758px;
    background: linear-gradient(to left, rgba(255, 255, 255, 0.29), rgba(91, 152, 199, 0.05), rgba(255, 255, 255, 0.33));
    animation-name: highlight-move;
    animation-duration: 20s;
    animation-delay: 3s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
    filter: blur(40px);
    transform: skewX(-55deg);
}

@keyframes highlight-move {
    0%   { right:-758px; display: block; }
    5% { right: 2678px; display: none; }
    100% { right: 2678px; display: none; }
}

@media (max-width: 1230px) {
    .main-bg {
        background: url("../img/manager.png") left 40px bottom/550px no-repeat, url("../img/main-img.jpg") center center no-repeat;
    }
}

@media (max-width: 1080px) {
    .main-bg {
        background: url("../img/manager.png") left 40px bottom/500px no-repeat, url("../img/main-img.jpg") center center no-repeat;
    }
}

@media (max-width: 986px) {
    .main-bg {
        background: url("../img/manager.png") left 40px bottom/450px no-repeat, url("../img/main-img.jpg") center center no-repeat;
    }
}

@media (max-width: 888px) {
    .main-bg {
        background: url("../img/main-img.jpg") center center no-repeat;
    }
}

nav {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    background: url(../img/bg-menu.png) repeat;
    box-shadow: 0 0 13px 3px rgba(0, 0, 0, 0.27);
    min-height: 40px;
}

nav a {
    color: rgba(0,0,0,.7);
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    padding: 8px 10px;
}

nav a.dropdown-a:after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

.dropdown-menu {
    position: absolute;
    top: 29px;
    display: block;
    z-index: 999999999999;
    background-color: #fff;
    box-shadow: 0 0 13px 3px rgba(0, 0, 0, 0.27);
    border: 1px solid rgba(0,0,0,.15);
}

.dPosition {
    position: relative;
}

a.dropdown-item-a {
    font-weight: normal;
    display: block;
    width: 100%;
    white-space: nowrap;
    color: #212529;
    padding: 10px 15px;
}

a.dropdown-item-a:hover {
    background-color: #f8f9fa;
}

a {
    text-decoration: none;
}

img {
    vertical-align: middle;
    border-style: none;
}

table {
    width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    border-collapse: collapse;
}

table td, table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.05);
}

.masonry {
    column-count: 8;
    column-gap: 1em;
}

@media (max-width: 1734px) {
    .masonry {
        column-count: 7;
    }
}

@media (max-width: 1452px) {
    .masonry {
        column-count: 6;
    }
}

@media (max-width: 1260px) {
    .masonry {
        column-count: 5;
    }
}

@media (max-width: 1042px) {
    .masonry {
        column-count: 4;
    }
}

@media (max-width: 812px) {
    .masonry {
        column-count: 3;
    }
}

@media (max-width: 578px) {
    .masonry {
        column-count: 2;
    }
}

@media (max-width: 360px) {
    .masonry {
        column-count: 1;
    }
}

.itemG {
    position: relative;
    margin: 0 0 1em;
    width: 100%;
    text-align: center;
}

img {
    max-width: 100%;
    height: auto;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
input[type=button].btn-block, input[type=reset].btn-block, input[type=submit].btn-block {
    width: 100%;
}
.btn-block {
    display: block;
    width: 100%;
}
.btn-group-lg>.btn, .btn-lg {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem;
}
.btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}
.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
button, input {
    overflow: visible;
}
button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
.form-control {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
label {
    display: inline-block;
    margin-bottom: .5rem;
}
.form-group {
    margin-bottom: 1rem;
}
textarea.form-control {
    height: auto;
}

.mobile-menu {
    display: none;
}

.full-width {
    display: block;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 99999999999;
    left: 0;
    top: 0;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0, 0.9);
    overflow-x: hidden;
    transition: 0.5s;
}

.open-menu {
    display: block;
    padding: 8px 10px;
    text-align: center;
}

.mobile-menu-items {
    display: block;
    padding: 8px 10px;
    text-align: center;
}

.dropdown-menu-items {
    position: relative;
    top: 0;
    box-shadow: none;
    margin-top: 10px;
}

.partners {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 20px;
}

.partner-item {
    background: #fff;
    box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.2), inset 0 1px 10px 0 rgba(0, 0, 0, 0.19);
    text-align: center;
    padding: 20px;
}

.adminMenu {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    gap: 20px;
}